import React from 'react';
import '../styles/index.css';
import PageLayout from "../components/PageLayout";
import { graphql } from "gatsby";
import { WorkQuery } from '../../graphql-types'
import ProjectGallery from '../components/work/ProjectGallery';

interface Props {
  data: WorkQuery;
}

const Work = ({data}: Props) => {
  return (
    <PageLayout title='Work | FS Woodworks' darkNavBar>
      <ProjectGallery projects={data.allSanityFeaturedProjects.nodes} />
    </PageLayout>
  );
}

export const query = graphql`
  query work {
    allSanityFeaturedProjects {
      nodes {
        id
        name
        isTextDark
        images {
          asset {
            url
          }
        }
      }
    }
    sanitySocial(title: {eq: "Instagram"}) {
      url
    }
  }
`;

export default Work;
