import React, { useState } from 'react'
import styles from '../../styles/work/NextProject.module.css'
import PlusIcon from '../svg/PlusIcon'
import ContactForm from '../contactUs/ContactForm'
import FSDialog from '../FSDialog'

const NextProject = (): JSX.Element => {
  const [open, setOpen] = useState(false)

  const toggleOpen = (isOpen: boolean) => () => {
    setOpen(isOpen)
  }

  return (
    <>
      <div className={ styles.outer }>
        <div className={ styles.container } onClick={ toggleOpen(true) }>
          <h2 className={ styles.title }>Interested in<br/> working with us?
          </h2>
          <p className={ styles.subtitle }>Request a Quote</p>
          <PlusIcon className={ styles.plus }/>
        </div>
      </div>
      <FSDialog open={ open } handleClose={ toggleOpen(false) }>
        <ContactForm/>
      </FSDialog>
    </>
  )
}

export default NextProject
