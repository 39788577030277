import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  circleColor?: string;
  plusColor?: string;
  className?: string;
}

const PlusIcon = ({circleColor = '#FF871C', plusColor = 'white', className}: Props): JSX.Element => {
  return (
    <SvgIcon width="88" height="88" viewBox="0 0 88 88" fill="none"
             className={ className }>
      <circle cx="44" cy="44" r="43.25" stroke={ circleColor } strokeWidth="1.5"
              fill='none'/>
      <line x1="44.75" y1="14" x2="44.75" y2="72" stroke={ plusColor }
            strokeWidth="1.5"/>
      <line x1="72" y1="43.75" x2="14" y2="43.75" stroke={ plusColor }
            strokeWidth="1.5"/>
    </SvgIcon>
  )
}

export default PlusIcon
