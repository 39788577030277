import React from 'react'
import styles from '../../styles/work/ProjectGallery.module.css'
import Grid from '@material-ui/core/Grid';
import { SanityFeaturedProjects } from '../../../graphql-types'
import ForwardIcon from '../svg/ForwardIcon'
import { Link } from 'gatsby'
import { SanityArray } from '../../types'
import NextProject from './NextProject';
import { Hidden } from '@material-ui/core'
import classNames from 'classnames'

export interface Props {
  projects: SanityArray<SanityFeaturedProjects>;
}

const ProjectGallery = ({projects}: Props) => {
  if (!projects?.length) return null

  return (
    <section className={styles.featuredContainer}>
      <h1 className={ styles.title }>Featured Projects</h1>
      <Grid container spacing={ 2 }>
        {
          projects.map((project) => {
            if (!project?.images?.length) return null
            const { name, images, isTextDark } = project
            const color = isTextDark ? 'black' : 'white'
            return (
              <>
                <Grid item sm={ 6 } xs={ 12 } className={ styles.projectGrid }>
                  <Link to={ `/project/${ project?.id }` }
                        className={ styles.link }>
                    <div className={ styles.projectContainer }>
                      <div className={ styles.projectContainerInner }
                           style={ { backgroundImage: `url(${ images[0]?.asset?.url })` } }>
                        <Hidden xsDown implementation='css'>
                          <div className={ styles.projectTitleContainer }
                               style={ { color } }>
                            <ForwardIcon circleColor={ color }
                                         arrowColor={ color }
                                         className={ styles.icon }/>
                            <p className={ styles.projectTitle }>{ name }</p>
                          </div>
                        </Hidden>
                      </div>
                    </div>
                  </Link>
                </Grid>
                <p
                  className={ classNames(styles.hiddenWeb, styles.projectTitle) }>{ name }</p>
              </>
            )
          })
        }
      </Grid>
      <NextProject />
    </section>
  )
}

export default ProjectGallery
